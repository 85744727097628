import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  IsloggedIn() {
    //validar hora 
    //if (localStorage.getItem('isLoggedin') && localStorage.getItem('Expires')) {
      
      // var expires = localStorage.getItem('Expires');
      // const myArray = expires.split(" ");
      // let hoy = new Date();
      // const mySecondArray = myArray[1].split(":");


      // if (hoy.getDate() > parseInt(myArray[0])) {//SI HOY EL DÍA ES DESPUÉS 
      //   return false;
      // }
      // else if (hoy.getDate() == parseInt(myArray[0])) { //SI ES EL MISMO DÍA
        
      //   if (hoy.getHours() == parseInt(mySecondArray[0])) {//SI LA HORA ES IGUAL
      //     if (hoy.getHours() < parseInt(mySecondArray[1])) {//SI LOS MINUTOS SON MENORES
      //       return !!localStorage.getItem('Token');
      //     }
      //     else { //SI LOS MINUTOS SON MAYORES
      //       return false;
      //     }
      //   }
      //   else if (hoy.getHours() < parseInt(mySecondArray[0])) {//SI LA HORA ES MENOR
      //     return !!localStorage.getItem('Token');
      //   }
      //   else { //SI LA HORA ES MAYOR
          
      //     return false;
      //   }
      // } 
      // else {// SÍ HOY ES EL DÍA ANTERIOR 
      //   return !!localStorage.getItem('Token');
      // }
    //   return !!localStorage.getItem('Token');
    // }
    // else {
    //   return false;
    // }
    return true;
  }
}
