import { Component, OnInit } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nobleui-angular';
  constructor(private os: OneSignal){
    this.os.init({
      appId: "eb472469-a15d-4ad8-af58-3fda98bbb64a" 
    });
  }
  onHandleTag(tag: string){
    this.os.sendTag("tech", tag).then(() =>{
      console.log("sent tag: " + tag);
    });
  }
  ngOnInit(): void {}

}
